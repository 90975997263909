$(document).ready(function () {
  // obtain plugin
  var cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: "en",
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
    },

    onAccept: function (cookie) {
      // ...
    },

    onChange: function (cookie, changed_preferences) {
      // ...
    },

    gui_options: {
      consent_modal: {
        layout: "bar", // box/cloud/bar
        position: "bottom center", // bottom/middle/top + left/right/center
        transition: "slide", // zoom/slide
        swap_buttons: false, // enable to invert buttons
      },
      settings_modal: {
        layout: "box", // box/bar
        // position: 'left',           // left/right
        transition: "zoom", // zoom/slide
      },
    },

    languages: {
      en: {
        consent_modal: {
          title: "",
          // description: 'Hi, this website uses cookies to understand how you interact with it. <button type="button" data-cc="c-settings" class="cc-link">More info</button>',
          description: "Hi, this website uses cookies to understand how you interact with it.",
          primary_btn: {
            text: "Accept all",
            role: "accept_all", // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: "No thanks",
            role: "accept_necessary", // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: "Cookie preferences",
          save_settings_btn: "Save settings",
          accept_all_btn: "Okay",
          reject_all_btn: "No thanks",
          close_btn_label: "Close",
          cookie_table_headers: [{ col1: "Name" }, { col2: "Domain" }, { col3: "Expiration" }, { col4: "Description" }],
          blocks: [
            {
              title: "Cookie usage 📢",
              description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
            },
            {
              title: "Strictly necessary cookies",
              description: "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
              toggle: {
                value: "necessary",
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: "Performance and Analytics cookies",
              description: "These cookies allow the website to remember the choices you have made in the past",
              toggle: {
                value: "analytics", // your cookie category
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: "^_ga", // match all cookies starting with "_ga"
                  col2: "google.com",
                  col3: "2 years",
                  col4: "description ...",
                  is_regex: true,
                },
                {
                  col1: "_gid",
                  col2: "google.com",
                  col3: "1 day",
                  col4: "description ...",
                },
              ],
            },
            {
              title: "More information",
              description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
            },
          ],
        },
      },
    },
  });

  $(".hero-slider").each(function () {
    var $slider = $(this).flickity({
      cellAlign: "left",
      cellSelector: ".slide",
      contain: true,
      pageDots: false,
      autoPlay: 6000,
      pauseAutoPlayOnHover: false,
      wrapAround: true,
      arrowShape: "M43.4426 50.0751L80.0002 13.5175L68.1955 1.71289L31.638 38.2704L31.5644 38.1969L19.7598 50.0016L19.8333 50.0751L19.7598 50.1486L31.5644 61.9533L31.638 61.8798L68.1955 98.4373L80.0002 86.6326L43.4426 50.0751Z",
    });
    $slider.on("settle.flickity", function (event, index) {
      $slider.flickity("playPlayer");
    });
    // $(this).on("pointerup", function (event, pointer) {
    //   $slider.flickity("playPlayer");
    // });
  });

  // $(".header-logo").click(function () {
  //   $("html, body").animate(
  //     {
  //       scrollTop: 0,
  //     },
  //     400
  //   );
  //   return false;
  // });

  $(".cta, .smooth-scroll").click(function () {
    $("html, body").animate(
      {
        scrollTop: $($(this).attr("href")).offset().top - 80,
      },
      400
    );
    return false;
  });

  var headerTriggered = false;
  function checkHeader() {
    if ($(window).scrollTop() > 50 && headerTriggered === false) {
      $("header").addClass("scrolled");
      headerTriggered = true;
    } else if ($(window).scrollTop() < 50 && headerTriggered === true) {
      $("header").removeClass("scrolled");
      headerTriggered = false;
    }
  }
  checkHeader();

  function throttle(func, wait) {
    let waiting = false;
    return function () {
      if (waiting) {
        return;
      }

      waiting = true;
      setTimeout(() => {
        func.apply(this, arguments);
        waiting = false;
      }, wait);
    };
  }

  const onScroll = throttle(() => {
    checkHeader();
  }, 100);

  document.addEventListener("scroll", onScroll);

  var contactForm = document.getElementById("contact-form");

  async function handleContactSubmit(event) {
    event.preventDefault();
    var status = contactForm.querySelector(".form-status");
    var data = new FormData(event.target);
    console.log(data);
    fetch(event.target.action, {
      method: contactForm.method,
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          status.innerHTML = "Thanks for your submission!";
          status.classList.add("active");
          contactForm.reset();
        } else {
          response.json().then((data) => {
            if (Object.hasOwn(data, "errors")) {
              status.innerHTML = data["errors"].map((error) => error["message"]).join(", ");
              status.classList.add("active");
            } else {
              status.innerHTML = "Oops! There was a problem submitting your form";
              status.classList.add("active");
            }
          });
        }
      })
      .catch((error) => {
        status.innerHTML = "Oops! There was a problem submitting your form";
        status.classList.add("active");
      });
  }

  if (contactForm) {
    contactForm.addEventListener("submit", handleContactSubmit);
  }
});
